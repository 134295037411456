
/**
 * @description: 获取二级域名 转换动态api 域名
 * @param {*} input 完整域名
 * @return {*}
 */
export function Domainparse(input) {
    if (typeof input !== "string") {
        throw new TypeError("Domain name must be a string.");
    }
    // Force domain to lowercase.
    var domain = input.slice(0).toLowerCase();
    // Handle FQDN.
    // TODO: Simply remove trailing dot?
    if (domain.charAt(domain.length - 1) === ".") {
        domain = domain.slice(0, domain.length - 1);
    }
    var parsed = {
        input: input,
        tld: null,
        sld: null,
        domain: null,
        subdomain: null,
        listed: false,
    };
    var domainParts = domain.split(".");
    // Non-Internet TLD
    if (domainParts[domainParts.length - 1] === "local") {
        return parsed;
    }
    var handlePunycode = function () {
        if (!/xn--/.test(domain)) {
            return parsed;
        }
        if (parsed.domain) {
            parsed.domain = Punycode.toASCII(parsed.domain);
        }
        if (parsed.subdomain) {
            parsed.subdomain = Punycode.toASCII(parsed.subdomain);
        }
        return parsed;
    };
    var rule = null;
    // Unlisted tld.
    if (!rule) {
        if (domainParts.length < 2) {
            return parsed;
        }
        parsed.tld = domainParts.pop();
        parsed.sld = domainParts.pop();
        parsed.domain = [parsed.sld, parsed.tld].join(".");
        if (domainParts.length) {
            parsed.subdomain = domainParts.pop();
        }
        return handlePunycode();
    }
    // At this point we know the public suffix is listed.
    parsed.listed = true;

    var tldParts = rule.suffix.split(".");
    var privateParts = domainParts.slice(
        0,
        domainParts.length - tldParts.length
    );

    if (rule.exception) {
        privateParts.push(tldParts.shift());
    }
    parsed.tld = tldParts.join(".");
    if (!privateParts.length) {
        return handlePunycode();
    }
    if (rule.wildcard) {
        tldParts.unshift(privateParts.pop());
        parsed.tld = tldParts.join(".");
    }

    if (!privateParts.length) {
        return handlePunycode();
    }
    parsed.sld = privateParts.pop();
    parsed.domain = [parsed.sld, parsed.tld].join(".");
    if (privateParts.length) {
        parsed.subdomain = privateParts.join(".");
    }
    return handlePunycode();
}

export function getDynamicDomain(host) {
    if (typeof host !== 'string') return null;

    const cleanedHost = host.startsWith('www.') ? host.substring(4) : host;

    const parsedDomain = Domainparse(cleanedHost);

    const subdomain = parsedDomain.subdomain ? `${parsedDomain.subdomain}.` : '';
    const domain = parsedDomain.domain || '';

    return `${subdomain}${domain}`; 
}